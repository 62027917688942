import React from "react";

const LogoFooter = () => {
        return (
          <svg width="135" height="26" viewBox="0 0 135 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M51.624 6.24365H57.0346C58.3526 6.24365 59.3931 6.33382 60.1561 6.51418C60.933 6.69453 61.4949 7.01361 61.8417 7.47143C62.2024 7.91537 62.3827 8.53967 62.3827 9.34432V11.9039C62.3827 12.7918 62.1885 13.4855 61.8001 13.9849C61.4255 14.4844 60.822 14.8381 59.9896 15.0462C59.1711 15.2543 58.0612 15.3584 56.66 15.3584H54.8704V19.7493H51.624V6.24365ZM56.9305 13.1317C57.7768 13.1317 58.3734 13.0346 58.7202 12.8404C59.067 12.6323 59.2404 12.2855 59.2404 11.7999V9.63566C59.2404 9.19172 59.074 8.90038 58.741 8.76165C58.4219 8.62291 57.8046 8.55354 56.8889 8.55354H54.8704V13.1317H56.9305Z" fill="#9096AA"/>
            <path d="M64.6292 5.18234H67.6675V19.7493H64.6292V5.18234Z" fill="#9096AA"/>
            <path d="M73.2701 19.9574C72.3267 19.9574 71.6191 19.8741 71.1474 19.7076C70.6896 19.5273 70.3636 19.2151 70.1694 18.7712C69.989 18.3134 69.8988 17.6336 69.8988 16.7318C69.8988 15.9272 69.996 15.296 70.1902 14.8381C70.3983 14.3803 70.7243 14.0543 71.1683 13.8601C71.6261 13.652 72.2504 13.5479 73.0411 13.5479H76.454V12.8612C76.454 12.4311 76.3915 12.112 76.2667 11.9039C76.1557 11.6958 75.9407 11.5502 75.6216 11.4669C75.3164 11.3837 74.8516 11.3421 74.2273 11.3421C73.3672 11.3421 72.1879 11.4253 70.6896 11.5918V9.67728C71.7856 9.53855 73.0689 9.46918 74.5395 9.46918C75.8297 9.46918 76.8216 9.56629 77.5153 9.76052C78.2228 9.95474 78.7292 10.2877 79.0344 10.7594C79.3396 11.2311 79.4922 11.9178 79.4922 12.8196V19.7493H76.5164V18.8336C76.2806 19.236 75.9615 19.5204 75.5591 19.6868C75.1568 19.8672 74.3938 19.9574 73.2701 19.9574ZM74.4562 18.0637C75.0666 18.0637 75.5036 18.0429 75.7672 18.0012C75.9615 17.9735 76.0933 17.9319 76.1626 17.8764C76.2459 17.8209 76.3152 17.7307 76.3707 17.6059C76.4262 17.5087 76.454 17.3631 76.454 17.1688V15.2127H74.373C73.9013 15.2127 73.5683 15.2474 73.3741 15.3168C73.1799 15.3723 73.0481 15.4971 72.9787 15.6913C72.9093 15.8717 72.8747 16.1977 72.8747 16.6694C72.8747 17.1272 72.9093 17.4463 72.9787 17.6267C73.0481 17.807 73.1799 17.9249 73.3741 17.9804C73.5822 18.0359 73.9429 18.0637 74.4562 18.0637Z" fill="#9096AA"/>
            <path d="M87.2013 19.9574C86.688 19.9574 86.2579 19.9366 85.9111 19.8949C85.5643 19.8533 85.2174 19.7701 84.8706 19.6452C84.4821 19.5204 84.1631 19.3469 83.9133 19.125C83.6775 18.903 83.4833 18.5978 83.3307 18.2093C83.1642 17.807 83.0809 17.3423 83.0809 16.8151V11.5502H81.0416V9.67728H83.0809V6.76389H86.1192V9.67728H88.8869V11.5502H86.1192V16.2948C86.1192 16.7804 86.1608 17.148 86.244 17.3977C86.3273 17.6336 86.4868 17.807 86.7227 17.918C86.9585 18.0151 87.3192 18.0637 87.8048 18.0637C87.999 18.0637 88.3597 18.029 88.8869 17.9596V19.8533C88.2349 19.9227 87.673 19.9574 87.2013 19.9574Z" fill="#9096AA"/>
            <path d="M91.4096 11.5502H89.5159V9.67728H91.4096V8.15816C91.4096 7.33963 91.5414 6.70146 91.805 6.24365C92.0825 5.77196 92.4987 5.44593 93.0536 5.26558C93.6224 5.07136 94.3785 4.97424 95.3219 4.97424C95.8352 4.97424 96.5913 5.02974 97.5901 5.14072V7.01361C96.7023 6.90263 96.1057 6.84713 95.8005 6.84713C95.2872 6.84713 94.9334 6.93037 94.7392 7.09685C94.545 7.24946 94.4479 7.54773 94.4479 7.99168V9.67728H97.2156V11.5502H94.4479V19.7493H91.4096V11.5502Z" fill="#9096AA"/>
            <path d="M103.535 19.9574C102.911 19.9574 102.398 19.9366 101.996 19.8949C101.593 19.8533 101.163 19.7701 100.705 19.6452C100.206 19.5065 99.8035 19.3123 99.4983 19.0625C99.1931 18.799 98.9365 18.4313 98.7284 17.9596C98.5203 17.5018 98.4162 16.9469 98.4162 16.2948V13.319C98.4162 12.6531 98.5203 12.0704 98.7284 11.571C98.9365 11.0715 99.207 10.6831 99.5399 10.4056C99.8313 10.1698 100.213 9.97555 100.684 9.82295C101.17 9.65647 101.642 9.55242 102.1 9.5108C102.627 9.48305 103.105 9.46918 103.535 9.46918C104.174 9.46918 104.68 9.48999 105.055 9.53161C105.443 9.55936 105.866 9.6426 106.324 9.78133C106.823 9.93393 107.226 10.142 107.531 10.4056C107.85 10.6692 108.121 11.0438 108.343 11.5294C108.551 12.0288 108.655 12.6253 108.655 13.319V16.2948C108.655 16.9191 108.551 17.4671 108.343 17.9388C108.148 18.4105 107.892 18.7781 107.573 19.0417C107.295 19.2776 106.914 19.4718 106.428 19.6244C105.956 19.7631 105.492 19.8533 105.034 19.8949C104.618 19.9366 104.118 19.9574 103.535 19.9574ZM103.535 18.0637C104.104 18.0637 104.534 18.022 104.826 17.9388C105.117 17.8417 105.318 17.696 105.429 17.5018C105.554 17.3076 105.616 17.0232 105.616 16.6486V12.8404C105.616 12.2855 105.471 11.897 105.179 11.675C104.888 11.4531 104.34 11.3421 103.535 11.3421C102.731 11.3421 102.183 11.4531 101.891 11.675C101.6 11.897 101.454 12.2855 101.454 12.8404V16.6486C101.454 17.0232 101.51 17.3076 101.621 17.5018C101.746 17.696 101.954 17.8417 102.245 17.9388C102.537 18.022 102.967 18.0637 103.535 18.0637Z" fill="#9096AA"/>
            <path d="M111.023 9.67728H113.915V11.4461C113.915 11.127 114.047 10.8149 114.311 10.5097C114.574 10.2045 114.907 9.95474 115.31 9.76052C115.726 9.56629 116.135 9.46918 116.537 9.46918H117.62V11.9872H116.579C115.719 11.9872 115.081 12.119 114.665 12.3826C114.262 12.6462 114.061 13.0832 114.061 13.6936V19.7493H111.023V9.67728Z" fill="#9096AA"/>
            <path d="M119.294 9.67728H122.332V10.9051C122.526 9.94781 123.518 9.46918 125.308 9.46918C126.251 9.46918 126.966 9.59404 127.451 9.84376C127.937 10.0796 128.263 10.4542 128.429 10.9675C128.596 10.4403 128.915 10.0588 129.387 9.82295C129.872 9.5871 130.601 9.46918 131.572 9.46918C132.446 9.46918 133.119 9.57323 133.59 9.78133C134.062 9.97555 134.388 10.2808 134.568 10.697C134.763 11.1132 134.86 11.6681 134.86 12.3618V19.7493H131.821V12.778C131.821 12.334 131.794 12.0219 131.738 11.8415C131.683 11.6473 131.558 11.5155 131.364 11.4461C131.183 11.3768 130.864 11.3421 130.406 11.3421C129.838 11.3421 129.428 11.3768 129.179 11.4461C128.943 11.5155 128.783 11.6473 128.7 11.8415C128.631 12.0219 128.596 12.334 128.596 12.778V19.7493H125.558V12.778C125.558 12.334 125.53 12.0219 125.474 11.8415C125.419 11.6473 125.294 11.5155 125.1 11.4461C124.92 11.3768 124.6 11.3421 124.143 11.3421C123.574 11.3421 123.165 11.3768 122.915 11.4461C122.679 11.5155 122.519 11.6473 122.436 11.8415C122.367 12.0219 122.332 12.334 122.332 12.778V19.7493H119.294V9.67728Z" fill="#9096AA"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8943 0.5C22.311 0.5 22.5586 0.965282 22.3259 1.31085L18.9625 6.30511C18.7563 6.61131 18.3057 6.61131 18.0995 6.30511L14.7362 1.31085C14.5035 0.96528 14.7511 0.5 15.1677 0.5H21.8943ZM31.0768 25.2703L27.1545 19.4459L23.0093 13.2906C22.891 13.1149 22.891 12.8851 23.0093 12.7094L27.1545 6.55413L31.0768 0.729676C31.1735 0.586108 31.3353 0.500025 31.5084 0.500025H38.9357C39.3523 0.500025 39.5999 0.965304 39.3672 1.31087L37.1073 4.66669L31.6911 12.7094C31.5728 12.8851 31.5728 13.1149 31.6911 13.2906L37.1073 21.3333L39.3672 24.6892C39.5999 25.0347 39.3523 25.5 38.9357 25.5H31.5084C31.3353 25.5 31.1735 25.4139 31.0768 25.2703ZM8.37998 4.22858L9.94604 6.55412L14.0913 12.7094C14.2096 12.8851 14.2096 13.1149 14.0913 13.2906L9.94604 19.4459L8.37998 21.7714C8.28329 21.915 8.12154 22.0011 7.94845 22.0011H0.521128C0.104509 22.0011 -0.143106 21.5358 0.0896079 21.1902L5.40944 13.2906C5.52774 13.1149 5.52774 12.8851 5.40944 12.7094L0.0896079 4.80977C-0.143106 4.4642 0.104509 3.99892 0.521128 3.99892H7.94845C8.12154 3.99892 8.28329 4.08501 8.37998 4.22858ZM21.8943 25.5C22.311 25.5 22.5586 25.0347 22.3259 24.6892L18.9625 19.6949C18.7563 19.3887 18.3057 19.3887 18.0995 19.6949L14.7362 24.6892C14.5035 25.0347 14.7511 25.5 15.1677 25.5H21.8943Z" fill="#9096AA"/>
          </svg>

        )
}

export {LogoFooter};