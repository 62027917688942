import React from "react";

const Logo = () => {
        return (
          <svg width="175" height="34" viewBox="0 0 175 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66.9897 8.23256H74.0108C75.721 8.23256 77.0712 8.34958 78.0613 8.58361C79.0695 8.81765 79.7986 9.23171 80.2487 9.82579C80.7167 10.4019 80.9508 11.212 80.9508 12.2561V15.5776C80.9508 16.7298 80.6987 17.6299 80.1946 18.278C79.7086 18.9261 78.9255 19.3852 77.8453 19.6552C76.7832 19.9252 75.3429 20.0603 73.5247 20.0603H71.2024V25.7581H66.9897V8.23256ZM73.8757 17.1708C74.9739 17.1708 75.748 17.0448 76.1981 16.7928C76.6481 16.5228 76.8732 16.0727 76.8732 15.4426V12.6342C76.8732 12.0581 76.6571 11.6801 76.2251 11.5C75.811 11.32 75.0099 11.23 73.8217 11.23H71.2024V17.1708H73.8757Z" fill="white"/>
            <path d="M83.8659 6.85537H87.8085V25.7581H83.8659V6.85537Z" fill="white"/>
            <path d="M95.0786 26.0281C93.8545 26.0281 92.9363 25.9201 92.3242 25.7041C91.7302 25.47 91.3071 25.065 91.0551 24.4889C90.821 23.8948 90.704 23.0127 90.704 21.8425C90.704 20.7984 90.83 19.9793 91.0821 19.3852C91.3521 18.7911 91.7752 18.368 92.3512 18.116C92.9453 17.8459 93.7554 17.7109 94.7816 17.7109H99.2102V16.8198C99.2102 16.2617 99.1292 15.8477 98.9672 15.5776C98.8232 15.3076 98.5441 15.1186 98.1301 15.0105C97.734 14.9025 97.1309 14.8485 96.3208 14.8485C95.2047 14.8485 93.6744 14.9565 91.7302 15.1726V12.6882C93.1524 12.5082 94.8176 12.4182 96.7259 12.4182C98.4001 12.4182 99.6873 12.5442 100.587 12.7962C101.506 13.0483 102.163 13.4803 102.559 14.0924C102.955 14.7045 103.153 15.5956 103.153 16.7658V25.7581H99.2912V24.5699C98.9852 25.092 98.5711 25.461 98.0491 25.6771C97.527 25.9111 96.5368 26.0281 95.0786 26.0281ZM96.6179 23.5708C97.41 23.5708 97.9771 23.5438 98.3191 23.4898C98.5711 23.4538 98.7422 23.3997 98.8322 23.3277C98.9402 23.2557 99.0302 23.1387 99.1022 22.9767C99.1742 22.8507 99.2102 22.6616 99.2102 22.4096V19.8712H96.5098C95.8978 19.8712 95.4657 19.9162 95.2137 20.0063C94.9616 20.0783 94.7906 20.2403 94.7006 20.4923C94.6106 20.7264 94.5656 21.1494 94.5656 21.7615C94.5656 22.3556 94.6106 22.7697 94.7006 23.0037C94.7906 23.2377 94.9616 23.3907 95.2137 23.4628C95.4837 23.5348 95.9518 23.5708 96.6179 23.5708Z" fill="white"/>
            <path d="M113.156 26.0281C112.49 26.0281 111.932 26.0011 111.482 25.9471C111.032 25.8931 110.582 25.7851 110.132 25.6231C109.628 25.461 109.214 25.236 108.89 24.948C108.584 24.6599 108.332 24.2639 108.134 23.7598C107.918 23.2377 107.81 22.6346 107.81 21.9505V15.1186H105.163V12.6882H107.81V8.90766H111.752V12.6882H115.344V15.1186H111.752V21.2754C111.752 21.9055 111.806 22.3826 111.914 22.7066C112.022 23.0127 112.229 23.2377 112.535 23.3817C112.841 23.5078 113.309 23.5708 113.94 23.5708C114.192 23.5708 114.66 23.5258 115.344 23.4358V25.8931C114.498 25.9831 113.769 26.0281 113.156 26.0281Z" fill="white"/>
            <path d="M118.617 15.1186H116.16V12.6882H118.617V10.7169C118.617 9.65477 118.788 8.82665 119.13 8.23256C119.49 7.62048 120.031 7.19742 120.751 6.96338C121.489 6.71134 122.47 6.58533 123.694 6.58533C124.36 6.58533 125.341 6.65734 126.638 6.80136V9.23171C125.485 9.08769 124.711 9.01568 124.315 9.01568C123.649 9.01568 123.19 9.12369 122.938 9.33972C122.686 9.53775 122.56 9.92481 122.56 10.5009V12.6882H126.151V15.1186H122.56V25.7581H118.617V15.1186Z" fill="white"/>
            <path d="M134.352 26.0281C133.542 26.0281 132.876 26.0011 132.354 25.9471C131.832 25.8931 131.274 25.7851 130.68 25.6231C130.032 25.443 129.51 25.191 129.114 24.867C128.718 24.5249 128.385 24.0478 128.115 23.4358C127.844 22.8417 127.709 22.1216 127.709 21.2754V17.4139C127.709 16.5498 127.844 15.7937 128.115 15.1456C128.385 14.4975 128.736 13.9934 129.168 13.6333C129.546 13.3273 130.041 13.0753 130.653 12.8772C131.283 12.6612 131.895 12.5262 132.489 12.4722C133.173 12.4362 133.794 12.4182 134.352 12.4182C135.181 12.4182 135.838 12.4452 136.324 12.4992C136.828 12.5352 137.377 12.6432 137.971 12.8232C138.619 13.0213 139.141 13.2913 139.537 13.6333C139.951 13.9754 140.302 14.4615 140.59 15.0916C140.86 15.7396 140.995 16.5138 140.995 17.4139V21.2754C140.995 22.0856 140.86 22.7967 140.59 23.4087C140.338 24.0208 140.005 24.4979 139.591 24.84C139.231 25.146 138.736 25.398 138.106 25.5961C137.494 25.7761 136.891 25.8931 136.297 25.9471C135.757 26.0011 135.109 26.0281 134.352 26.0281ZM134.352 23.5708C135.091 23.5708 135.649 23.5168 136.027 23.4087C136.405 23.2827 136.666 23.0937 136.81 22.8417C136.972 22.5896 137.053 22.2206 137.053 21.7345V16.7928C137.053 16.0727 136.864 15.5686 136.486 15.2806C136.108 14.9925 135.397 14.8485 134.352 14.8485C133.308 14.8485 132.597 14.9925 132.219 15.2806C131.841 15.5686 131.652 16.0727 131.652 16.7928V21.7345C131.652 22.2206 131.724 22.5896 131.868 22.8417C132.03 23.0937 132.3 23.2827 132.678 23.4087C133.056 23.5168 133.614 23.5708 134.352 23.5708Z" fill="white"/>
            <path d="M144.068 12.6882H147.822V14.9835C147.822 14.5695 147.993 14.1644 148.335 13.7684C148.677 13.3723 149.109 13.0483 149.631 12.7962C150.171 12.5442 150.702 12.4182 151.224 12.4182H152.629V15.6856H151.278C150.162 15.6856 149.334 15.8567 148.794 16.1987C148.272 16.5408 148.011 17.1078 148.011 17.9V25.7581H144.068V12.6882Z" fill="white"/>
            <path d="M154.801 12.6882H158.744V14.2814C158.996 13.0393 160.283 12.4182 162.605 12.4182C163.83 12.4182 164.757 12.5802 165.387 12.9042C166.017 13.2103 166.44 13.6964 166.656 14.3624C166.872 13.6783 167.286 13.1833 167.898 12.8772C168.528 12.5712 169.473 12.4182 170.734 12.4182C171.868 12.4182 172.741 12.5532 173.353 12.8232C173.965 13.0753 174.388 13.4713 174.622 14.0114C174.874 14.5515 175 15.2716 175 16.1717V25.7581H171.058V16.7118C171.058 16.1357 171.022 15.7306 170.95 15.4966C170.878 15.2446 170.716 15.0735 170.464 14.9835C170.23 14.8935 169.815 14.8485 169.221 14.8485C168.483 14.8485 167.952 14.8935 167.628 14.9835C167.322 15.0735 167.115 15.2446 167.007 15.4966C166.917 15.7306 166.872 16.1357 166.872 16.7118V25.7581H162.929V16.7118C162.929 16.1357 162.893 15.7306 162.821 15.4966C162.749 15.2446 162.587 15.0735 162.335 14.9835C162.101 14.8935 161.687 14.8485 161.093 14.8485C160.355 14.8485 159.824 14.8935 159.5 14.9835C159.194 15.0735 158.987 15.2446 158.879 15.4966C158.789 15.7306 158.744 16.1357 158.744 16.7118V25.7581H154.801V12.6882Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.4111 0.779419C28.9517 0.779419 29.2731 1.38319 28.9711 1.83161L24.6067 8.3124C24.3391 8.70973 23.7543 8.70973 23.4867 8.3124L19.1224 1.83161C18.8204 1.38319 19.1417 0.779419 19.6823 0.779419H28.4111ZM40.3267 32.9226L35.237 25.3645L29.858 17.3771C29.7044 17.1492 29.7044 16.8509 29.858 16.6229L35.237 8.63554L40.3267 1.07746C40.4522 0.891156 40.6621 0.779451 40.8867 0.779451H50.5248C51.0654 0.779451 51.3867 1.38322 51.0847 1.83164L48.1522 6.18631L41.1239 16.6229C40.9704 16.8509 40.9704 17.1491 41.1239 17.3771L48.1522 27.8137L51.0847 32.1684C51.3867 32.6168 51.0654 33.2206 50.5248 33.2206H40.8867C40.6621 33.2206 40.4522 33.1089 40.3267 32.9226ZM10.8742 5.61779L12.9064 8.63552L18.2855 16.6229C18.439 16.8509 18.439 17.1491 18.2855 17.3771L12.9064 25.3645L10.8742 28.3822C10.7488 28.5685 10.5389 28.6802 10.3143 28.6802H0.67624C0.135616 28.6802 -0.185701 28.0764 0.116279 27.628L7.01954 17.3771C7.17306 17.1491 7.17306 16.8509 7.01954 16.6229L0.116279 6.37198C-0.185701 5.92355 0.135616 5.31979 0.67624 5.31979H10.3143C10.5389 5.31979 10.7488 5.43149 10.8742 5.61779ZM28.4111 33.2206C28.9517 33.2206 29.2731 32.6168 28.9711 32.1684L24.6067 25.6876C24.3391 25.2903 23.7543 25.2903 23.4867 25.6876L19.1224 32.1684C18.8204 32.6168 19.1417 33.2206 19.6823 33.2206H28.4111Z" fill="url(#paint0_linear_116_688)"/>
            <defs>
              <linearGradient id="paint0_linear_116_688" x1="35.1013" y1="-0.0132757" x2="12.5844" y2="32.7437" gradientUnits="userSpaceOnUse">
                <stop offset="0.0520833" stop-color="#FF8820"/>
                <stop offset="0.744792" stop-color="#FF7B2C"/>
                <stop offset="1" stop-color="#FF386A"/>
              </linearGradient>
            </defs>
          </svg>
        )
}

export {Logo};