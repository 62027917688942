import React from "react";

const FbIco = () => {
        return (
          <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4919 0.984375H2.05444C0.947021 0.984375 0.0856934 1.88672 0.0856934 2.95312V17.3906C0.0856934 18.498 0.947021 19.3594 2.05444 19.3594H7.67358V13.125H5.0896V10.1719H7.67358V7.95703C7.67358 5.41406 9.19116 3.97852 11.488 3.97852C12.6365 3.97852 13.7849 4.18359 13.7849 4.18359V6.68555H12.5134C11.2419 6.68555 10.8318 7.46484 10.8318 8.28516V10.1719H13.6619L13.2107 13.125H10.8318V19.3594H16.4919C17.5583 19.3594 18.4607 18.498 18.4607 17.3906V2.95312C18.4607 1.88672 17.5583 0.984375 16.4919 0.984375Z" fill="#9096AA"/>
          </svg>

        )
}

export {FbIco};