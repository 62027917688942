import 'bootstrap/dist/css/bootstrap.css';
import "./style/index";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Dashboard from "./page/dashboard/Dashboard";
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from "web3";

function getLibrary(provider) {
    return new Web3(provider);
}

function App() {
  return (
      <Web3ReactProvider getLibrary={getLibrary}>
          <div className={"app"}>
              <Header/>
              <div className="wrapper">
                  <div className="container">
                      <Dashboard/>
                  </div>
              </div>
              <Footer/>
          </div>
      </Web3ReactProvider>
  );
}

export default App;
