import React, {useState} from 'react';
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { RewardIco } from "../svg/reward";
import {useWeb3React} from "@web3-react/core";
import Web3 from "web3";
import {ABI_STAKE, CONTRACT_STAKE, FEE} from "../../web3/utils";



const GetStakeModal = ({setHash}) => {
  const {account, active, library} = useWeb3React();
  const [modal, setModal] = useState(false);
  const [loading, setloading] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const [amount, setAmount] = useState(0)

  const getStake = async (e) => {
    e.preventDefault();
    try {
      if(!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      setloading(!loading)
      await contract.methods.getStake((amount * Math.pow(10, 8)).toString()).send({value: FEE, from: account}).then(res=> {
        setHash(res.transactionHash);
        setloading(!loading)
        toggle();
      }).catch(err=> {
        console.error(err);
        setloading(!loading)
      })
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <button className="btn btn-primary" onClick={toggle}>
        <RewardIco/>
        Get stake
      </button>
      <Modal isOpen={modal} toggle={toggle} className={'modal-app'}>
        <ModalHeader toggle={toggle}>
          Get stake
        </ModalHeader>
        <ModalBody>
          <Form className="form">
            <div className="form-control">
              <Input type="text" id="amount" value={amount} onChange={e=>setAmount(e.currentTarget.value)} />
              <Label htmlFor="amount">amount</Label>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" type={'submit'} onClick={e=>getStake(e)}>
            Get Stake
          </Button>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
        <div className={"loading-holder "+ (loading ? 'active' : '')}>
          <div className={"lds-ellipsis"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GetStakeModal;