import React from "react";

const CopyIco = () => {
        return (
          <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0.5C15.3063 0.5 16.4175 1.33485 16.8293 2.50009L7.12777 2.5C5.34473 2.5 4.69816 2.68565 4.04631 3.03427C3.39446 3.38288 2.88288 3.89446 2.53427 4.54631C2.18565 5.19816 2 5.84473 2 7.62777L2.00009 18.3293C0.83485 17.9175 0 16.8063 0 15.5V6.5C0 3.18629 2.68629 0.5 6 0.5H14ZM18 4.5C19.6569 4.5 21 5.84315 21 7.5V20.5C21 22.1569 19.6569 23.5 18 23.5H7C5.34315 23.5 4 22.1569 4 20.5V7.5C4 5.84315 5.34315 4.5 7 4.5H18ZM18 6.5H7C6.44772 6.5 6 6.94772 6 7.5V20.5C6 21.0523 6.44772 21.5 7 21.5H18C18.5523 21.5 19 21.0523 19 20.5V7.5C19 6.94772 18.5523 6.5 18 6.5Z" fill="#616A88"/>
          </svg>
        )
}

export {CopyIco};