import React from "react";

const Sun = () => {
        return (
          <svg className="sun" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle className="st1" cx="10" cy="10" r="5" stroke="url(#paint0_linear_116_973)" stroke-width="1.5"/>
            <path className="st2" d="M10 0.833313V1.66665M10 19.1666L10 18.3333" stroke="url(#paint1_linear_116_973)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path className="st3" d="M3.51807 16.4818L4.10732 15.8926M16.4817 3.51819L15.8924 4.10744" stroke="url(#paint2_linear_116_973)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path className="st4" d="M3.51807 3.51819L4.10732 4.10744M16.4817 16.4818L15.8924 15.8926" stroke="url(#paint3_linear_116_973)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path className="st5" d="M0.833496 10.0001H1.66683M19.1668 10.0001L18.3335 10.0001" stroke="url(#paint4_linear_116_973)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
              <linearGradient id="paint0_linear_116_973" x1="15" y1="5" x2="2.93169" y2="9.80269" gradientUnits="userSpaceOnUse">
                <stop offset="0.0520833" stop-color="#FF8820"/>
                <stop offset="1" stop-color="#FF386A"/>
              </linearGradient>
              <linearGradient id="paint1_linear_116_973" x1="10" y1="19.1666" x2="6.55195" y2="18.694" gradientUnits="userSpaceOnUse">
                <stop offset="0.0520833" stop-color="#FF8820"/>
                <stop offset="1" stop-color="#FF386A"/>
              </linearGradient>
              <linearGradient id="paint2_linear_116_973" x1="16.4817" y1="3.51819" x2="18.5857" y2="6.29051" gradientUnits="userSpaceOnUse">
                <stop offset="0.0520833" stop-color="#FF8820"/>
                <stop offset="1" stop-color="#FF386A"/>
              </linearGradient>
              <linearGradient id="paint3_linear_116_973" x1="16.4817" y1="16.4818" x2="13.7094" y2="18.5858" gradientUnits="userSpaceOnUse">
                <stop offset="0.0520833" stop-color="#FF8820"/>
                <stop offset="1" stop-color="#FF386A"/>
              </linearGradient>
              <linearGradient id="paint4_linear_116_973" x1="19.1668" y1="10.0001" x2="18.6942" y2="13.4481" gradientUnits="userSpaceOnUse">
                <stop offset="0.0520833" stop-color="#FF8820"/>
                <stop offset="1" stop-color="#FF386A"/>
              </linearGradient>
            </defs>
          </svg>

        )
}

export {Sun};