import React from "react";

const RewardIco = () => {
        return (
          <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2929 15.2929C17.6834 14.9024 18.3166 14.9024 18.7071 15.2929C19.0976 15.6834 19.0976 16.3166 18.7071 16.7071L17.413 18H25C25.5128 18 25.9355 18.386 25.9933 18.8834L26 19C26 19.5523 25.5523 20 25 20H17.415L18.7071 21.2929C19.0676 21.6534 19.0953 22.2206 18.7903 22.6129L18.7071 22.7071C18.3166 23.0976 17.6834 23.0976 17.2929 22.7071L14.2929 19.7071C13.9024 19.3166 13.9024 18.6834 14.2929 18.2929L17.2929 15.2929ZM22 8.84583C22 7.77757 21.9266 7.39726 21.7175 7.00633C21.5492 6.69171 21.3083 6.45077 20.9937 6.28251C20.6027 6.07344 20.2224 6 19.1542 6H2V15.1542C2 16.2224 2.07344 16.6027 2.28251 16.9937C2.45077 17.3083 2.69171 17.5492 3.00633 17.7175C3.39726 17.9266 3.77757 18 4.84583 18H11C11.5523 18 12 18.4477 12 19C12 19.5523 11.5523 20 11 20H4.84583C3.47564 20 2.78342 19.8663 2.06313 19.4811C1.39998 19.1265 0.873546 18.6 0.518886 17.9369C0.133674 17.2166 0 16.5244 0 15.1542V4H0.00200009L0.00211334 3.9453C0.0192074 2.94498 0.142237 2.38674 0.452103 1.80734C0.763186 1.22567 1.22567 0.763186 1.80734 0.452103C2.43503 0.116415 3.03787 0 4.20486 0H15.7951C16.9621 0 17.565 0.116415 18.1927 0.452103C18.7743 0.763186 19.2368 1.22567 19.5479 1.80734C19.8654 2.4011 19.9868 2.97263 19.999 4.02047C20.844 4.07473 21.3821 4.22221 21.9369 4.51889C22.6 4.87355 23.1265 5.39997 23.4811 6.06313C23.8663 6.78341 24 7.47564 24 8.84583V15C24 15.5523 23.5523 16 23 16C22.4477 16 22 15.5523 22 15V8.84583ZM18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H16C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11H18ZM15.7951 2H4.20486C3.3398 2 3.04887 2.05618 2.75054 2.21573C2.5174 2.34041 2.34041 2.5174 2.21573 2.75054C2.06949 3.02398 2.01009 3.29121 2.0012 4H17.9988C17.9899 3.29121 17.9305 3.02398 17.7843 2.75054C17.6596 2.5174 17.4826 2.34041 17.2495 2.21573C16.9511 2.05618 16.6602 2 15.7951 2Z" fill="white"/>
          </svg>
        )
}

export {RewardIco};