import React, {useEffect, useState} from 'react';
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { StakeIco } from "../svg/stake";
import {useWeb3React} from "@web3-react/core";
import Web3 from "web3";
import {ABI_STAKE, ABI_TOKEN, CONTRACT_STAKE, CONTRACT_TOKEN, FEE} from "../../web3/utils";


const StakeSend = ({setHash}) => {


  const {account, active, library} = useWeb3React();
  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [apr, setApr] = useState(false);
  const [loading, setloading] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const [balance, setBalance] = useState(0);



  const toggle = () => {
    setModal(!modal);
  };

  const allow = async () => {
    try {
      if(!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);

      await contract.methods.balanceOf(account).call().then(res=> {
        setBalance(res)
      });

      await contract.methods.allowance(account, CONTRACT_STAKE).call().then(res=> {
        setAllowance(res);
      });
    } catch (e) {
      console.error('allow', e);
    }
  }

  const Approve = async (e) => {
    e.preventDefault();
    try {
      if(!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);
      setloading(true);
      await contract.methods.approve(CONTRACT_STAKE, balance).send({from: account}).then(res=> {
        setApr(!apr);
        setHash(res.transactionHash);
        setloading(false)
      }).catch(err=> {
        console.log(err);
        setloading(false);
      })
    } catch (e) {
      console.error('allow', e);
    }
  }

  const ToStake = async (e) => {
    e.preventDefault();
    try {
      if(!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      setloading(!loading)
      await contract.methods.toStake((Number(amount) * Math.pow(10, 8)).toString()).send({value: FEE,from: account}).then(res=> {
        setHash(res.transactionHash);
        setloading(!loading);
        toggle();
      }).catch(err=> {
        console.log(err);
        setloading(!loading)
      })
    } catch (e) {
      console.error('allow', e);
    }
  }

  useEffect(()=> {
    allow().then(console.log).catch(console.error);
  }, [library, account, active])

  useEffect(()=> {
    if(Number(allowance) < Number(balance)) {
      setApr(false);
    } else {
      setApr(true);
    }
  }, [allowance, balance]);


  return (
    <>
      <button className="btn btn-primary" onClick={toggle}>
        <StakeIco/>
        To stake
      </button>
      <Modal isOpen={modal} toggle={toggle} className={'modal-app'}>
        <ModalHeader toggle={toggle}>
          To stake
        </ModalHeader>
        <ModalBody>
          <Form className="form">
            <div className="form-control">
              <Input type="text" id="amount" value={amount} onChange={e=>setAmount(e.currentTarget.value)}/>
              <Label htmlFor="amount">amount</Label>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          {apr ? (
              <Button color="secondary" type={'submit'} onClick={e=>ToStake(e)}>
                Send
              </Button>
          ) : (
              <Button color="secondary" type={'submit'} onClick={e=>Approve(e)}>
                Approve
              </Button>
          )}

          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
        <div className={"loading-holder "+ (loading ? 'active' : '')}>
          <div className={"lds-ellipsis"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StakeSend;