import React from "react";

const VkIco = () => {
        return (
          <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6152 1.51172C21.7383 1.01953 21.6152 0.609375 20.877 0.609375H18.457C17.8418 0.609375 17.5547 0.9375 17.3906 1.30664C17.3906 1.30664 16.1602 4.30078 14.4375 6.26953C13.8633 6.80273 13.6172 7.00781 13.2891 7.00781C13.166 7.00781 12.9199 6.80273 12.9199 6.31055V1.51172C12.9199 0.896484 12.7559 0.609375 12.2227 0.609375H8.44922C8.03906 0.609375 7.83398 0.896484 7.83398 1.18359C7.83398 1.75781 8.69531 1.88086 8.77734 3.52148V7.08984C8.77734 7.86914 8.6543 8.0332 8.32617 8.0332C7.50586 8.0332 5.53711 4.99805 4.34766 1.55273C4.10156 0.896484 3.85547 0.609375 3.24023 0.609375H0.820312C0.164062 0.609375 0 0.9375 0 1.30664C0 1.96289 0.820312 5.12109 3.81445 9.3457C5.82422 12.2168 8.6543 13.7344 11.1973 13.7344C12.7148 13.7344 12.9199 13.4062 12.9199 12.832C12.9199 10.084 12.7969 9.79688 13.5352 9.79688C13.9043 9.79688 14.5195 10.002 15.9551 11.3965C17.5957 13.0371 17.8828 13.7344 18.7852 13.7344H21.2051C21.9023 13.7344 22.2305 13.4062 22.0254 12.709C21.5742 11.3145 18.498 8.36133 18.334 8.15625C17.9648 7.70508 18.0879 7.5 18.334 7.08984C18.334 7.08984 21.2871 2.90625 21.6152 1.51172Z" fill="#9096AA"/>
          </svg>
        )
}

export {VkIco};