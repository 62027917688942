import React from "react";
import { DashboardIco } from "../../svg/dashboard";
import { PoolIco } from "../../svg/pool";
import { BuyIco } from "../../svg/buy";
import { DelegatIco } from "../../svg/delegat";
import { TransactionIco } from "../../svg/transaction";

const Navigation = ({active}) => {
    return (
        <>
            {/*<ul className={'menu ' + (active ? 'active' : '')}>*/}
            {/*    <li>*/}
            {/*        <a href="/">*/}
            {/*            <DashboardIco/>*/}
            {/*            Dashboard*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <a href="/">*/}
            {/*            <TransactionIco/>*/}
            {/*            Transactions*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <a href="/">*/}
            {/*            <DelegatIco/>*/}
            {/*            XXDelegate*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <a href="/">*/}
            {/*            <BuyIco/>*/}
            {/*            Buy coins*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <a href="/">*/}
            {/*            <PoolIco/>*/}
            {/*            Delegate Pool*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*</ul>*/}
        </>
    )
}

export default Navigation;