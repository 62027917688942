import React, {useState} from 'react';
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ReinvestIco } from "../svg/reinvest";
import {useWeb3React} from "@web3-react/core";
import Web3 from "web3";
import {ABI_STAKE, CONTRACT_STAKE, FEE} from "../../web3/utils";


const ReinvestModal = ({setHash}) => {
  const {account, active, library} = useWeb3React()
  const [modal, setModal] = useState(false);
  const [loading, setloading] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  
  const Reinvest = async (e) => {
    e.preventDefault();
    try {
      if(!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      setloading(!loading)
      await contract.methods.reinvest().send({value: FEE,from: account}).then(res=> {
        setHash(res.transactionHash);
        setloading(!loading)
        toggle();
      }).catch(err=> {
        setloading(!loading);
        console.error(err);
      })
    } catch (e) {
      console.error(e);
    }
  }


  return (
    <>
      <button className="btn btn-primary" onClick={toggle}>
        <ReinvestIco/>
        Reinvest
      </button>
      <Modal isOpen={modal} toggle={toggle} className={'modal-app'}>
        <ModalHeader toggle={toggle}>
          Reinvest
        </ModalHeader>
        {/*<ModalBody>*/}
        {/*          </ModalBody>*/}
        <ModalFooter>
          <Button color="secondary" type={'submit'} onClick={e=>Reinvest(e)}>
            Reinvest
          </Button>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
        <div className={"loading-holder "+ (loading ? 'active' : '')}>
          <div className={"lds-ellipsis"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReinvestModal;