import React from "react";

const TwIco = () => {
        return (
          <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8669 0.984375H2.42944C1.32202 0.984375 0.460693 1.88672 0.460693 2.95312V17.3906C0.460693 18.498 1.32202 19.3594 2.42944 19.3594H16.8669C17.9333 19.3594 18.8357 18.498 18.8357 17.3906V2.95312C18.8357 1.88672 17.9333 0.984375 16.8669 0.984375ZM14.8572 7.50586C14.8572 7.62891 14.8572 7.75195 14.8572 7.875C14.8572 11.4023 12.1501 15.5039 7.18726 15.5039C5.66968 15.5039 4.27515 15.0938 3.08569 14.3145C3.29077 14.3555 3.49585 14.3555 3.70093 14.3555C4.97241 14.3555 6.12085 13.9043 7.06421 13.207C5.87476 13.166 4.89038 12.3867 4.52124 11.3203C4.97241 11.4023 5.34155 11.4023 5.75171 11.2793C4.52124 11.0332 3.6189 9.9668 3.6189 8.6543V8.61328C3.94702 8.81836 4.35718 8.94141 4.80835 8.94141C4.07007 8.44922 3.6189 7.62891 3.6189 6.72656C3.6189 6.19336 3.74194 5.74219 3.98804 5.33203C5.30054 6.97266 7.3103 8.03906 9.52515 8.16211C9.15601 6.35742 10.5095 4.83984 12.1501 4.83984C12.9294 4.83984 13.6267 5.16797 14.1189 5.70117C14.7341 5.57812 15.3083 5.37305 15.8416 5.04492C15.6365 5.70117 15.1853 6.19336 14.6521 6.52148C15.1853 6.48047 15.7185 6.31641 16.2107 6.11133C15.8416 6.64453 15.3494 7.13672 14.8572 7.50586Z" fill="#9096AA"/>
          </svg>

        )
}

export {TwIco};