import React from "react";

const StakeIco = () => {
        return (
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5C0 5.87258 5.37258 0.5 12 0.5ZM12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5ZM12 6.5C12.5523 6.5 13 6.94772 13 7.5V11.5H17C17.5523 11.5 18 11.9477 18 12.5C18 13.0523 17.5523 13.5 17 13.5H13V17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5V13.5H7C6.44772 13.5 6 13.0523 6 12.5C6 11.9477 6.44772 11.5 7 11.5H11V7.5C11 6.94772 11.4477 6.5 12 6.5Z" fill="white"/>
          </svg>
        )
}

export {StakeIco};