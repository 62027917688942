import React, {useEffect, useState} from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { CopyIco } from "../../components/svg/copy";
import { MoreTransactionIco } from "../../components/svg/moreTransaction";
import { TotalStakeIco } from "../../components/svg/totalStake";
import { TotalLiquidityIco } from "../../components/svg/totalLiquidity";
import { TotalUserIco } from "../../components/svg/totalUser";
import StakeSend from "../../components/modal/modalStake";
import ReinvestModal from "../../components/modal/modalReinvest";
import RewardModal from "../../components/modal/modalReward";
import {useWeb3React} from "@web3-react/core";
import Web3 from "web3";
import {ABI_STAKE, ABI_TOKEN, CONTRACT_STAKE, CONTRACT_TOKEN} from "../../web3/utils";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import GetStakeModal from "../../components/modal/modalGetStake";
import {getPrice} from "../../web3/price";

const Dashboard = () => {
    const {active, account, library} = useWeb3React()
    const [hash, setHash] = useState();
    const [balance, setBalance] = useState(0);
    const [stakeUpdate, setStakeUpdate ] = useState();
    const [stake, setStake] = useState(0);
    const [percent, setPercent] = useState(0);
    const [rewardCoin, setReward] = useState(0);
    const [days, setDays] = useState(0)
    const [events, setEvents] = useState([]);
    const [price, setPrice] = useState(0.07);

    const toUsdt = ( amount ) => {
      return (amount * price).toFixed(3);
    }

    const convert = (amount) => {
      amount = Number(amount) / Math.pow(10, 8);
      return (amount);
    }

    const loadBalance = async () => {
      try {
        if(!library) return;
        const web3 = new Web3(library);
        const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
        const token = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);
        await token.methods.balanceOf(account).call().then(res=> {
          setBalance(convert(res));
        });

        await contract.methods.stakeBalance(account).call().then(res=> {
          setStake(convert(res));
        })

      } catch (e) {
        console.error('allow', e);
      }
    }

    const calc = async() => {
      try {
        if(!library) return;
        const web3 = new Web3(library);
        const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
        await contract.methods.calc(account).call({from: account}).then(res=> {
          setReward(res[0] / Math.pow(10, 8));
          setPercent(res[1] / 100);
          // 2901 2363 7658
          setDays(res[2])
        });

      } catch (e) {
        console.error(e)
      }
    }

    const LoadEvents = async () => {
      try {
        if(!library) return;
        const web3 = new Web3(library);
        const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
        await contract.getPastEvents("Reinvest", (error, event) => {
            if(!error) console.log(event);
            else console.log(`Error: ${error}`);
        })

      } catch (e) {
        console.error(e)
      }
    }

    useEffect(()=> {
      loadBalance().then().catch(console.error);
      calc().then().catch(console.error);
      LoadEvents().then().catch(console.error);
      getPrice().then(res=>setPrice(res)).catch(console.error);
    }, [library, account, active, hash]);


    useEffect(() => {
      const id = setInterval(async ()=> {
       await calc()
      }, 1000 * 60);
      return ()=> clearInterval(id);
    }, [stake, percent, account, stakeUpdate, hash])
    return (
      <>
        <div className="head-page">
          <h1>Dashboard</h1>
        </div>
        <div className="dashboard-block">
          <div className="holder-block">
            <div className="block-wrapper balance-block">
              <div className="holder">
                <h3 className="title">Balance</h3>
                <div className="balance-info">
                  <div className="left">
                    <strong className="number">{balance}</strong>
                    <div className="current-info">
                      <span className="name">USDT</span>
                      <strong>{toUsdt(balance)}</strong>
                    </div>
                  </div>
                </div>
                <div className="btn-holder">
                  <form action="/" className="w-100">
                    {active && (
                        <div className="copy">
                          <span>{account.substring(0, 12)}...</span>
                          <CopyToClipboard text={account}>
                            <button onClick={e=>e.preventDefault()}>
                              <CopyIco/>
                            </button>
                          </CopyToClipboard>
                        </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <div className="block-wrapper stake-block">
              <div className="holder">
                <h3 className="title">On stake</h3>
                <div className="balance-info">
                  <div className="left">
                    <strong className="number">{stake}</strong>
                    <div className="current-info">
                      <span className="name">USDT</span>
                      <strong>{toUsdt(stake)}</strong>
                    </div>
                  </div>
                </div>
                <div className="btn-holder">
                  <StakeSend setHash={setHash}/>
                  <GetStakeModal setHash={setHash}/>
                </div>
              </div>
            </div>
            <div className="block-wrapper reward-block">
              <div className="holder">
                <h3 className="title">Reward</h3>
                <div className="balance-info">
                  <div className="left">
                    <strong className="number">{rewardCoin}</strong>
                    <div className="current-info">
                      <span className="name">USDT</span>
                      <strong>{toUsdt(rewardCoin)}</strong>
                    </div>
                  </div>
                  <div className="wallet-percent">
                    <strong className="percent">
                      {percent}%
                    </strong>
                    <div className="day-info">
                      <span>Wallet opening day/s:</span>
                      <strong>{days}</strong>
                    </div>
                  </div>
                </div>
                <div className="btn-holder">
                  <ReinvestModal setHash={setHash}/>
                  <RewardModal setHash={setHash}/>
                </div>
              </div>
            </div>
            {/*<div className="history-wrapper">*/}
            {/*  <div className="head">*/}
            {/*    <h3>History</h3>*/}
            {/*    /!*<a href="/" className="link">*!/*/}
            {/*    /!*  <MoreTransactionIco/>*!/*/}
            {/*    /!*  <span>all transactions</span>*!/*/}
            {/*    /!*</a>*!/*/}
            {/*  </div>*/}
            {/*  <Table className={"table"}>*/}
            {/*    <Thead>*/}
            {/*      <Tr>*/}
            {/*        <Th>Date</Th>*/}
            {/*        <Th>Event</Th>*/}
            {/*        <Th>TxID</Th>*/}
            {/*        <Th>Amount</Th>*/}
            {/*      </Tr>*/}
            {/*    </Thead>*/}
            {/*    <Tbody>*/}
            {/*      /!*<Tr>*!/*/}
            {/*      /!*  <Td>03.03.2022</Td>*!/*/}
            {/*      /!*  <Td>Get Reward</Td>*!/*/}
            {/*      /!*  <Td><a href="/">0xJd183Kda...8amAn</a></Td>*!/*/}
            {/*      /!*  <Td>0.3048817747 XXP</Td>*!/*/}
            {/*      /!*</Tr>*!/*/}
            {/*    </Tbody>*/}
            {/*  </Table>*/}
            {/*</div>*/}
            {/*<div className="total-holder">*/}
            {/*  <div className="inner">*/}
            {/*    <div className="item total-stake">*/}
            {/*      <h3>Total stake</h3>*/}
            {/*      <div className="holder-info">*/}
            {/*        <div className="ico">*/}
            {/*          <TotalStakeIco/>*/}
            {/*        </div>*/}
            {/*        <div className="info">*/}
            {/*          <span className="number">0</span>*/}
            {/*          <div className="current-info">*/}
            {/*            <span className="name">USDT</span>*/}
            {/*            <strong>0</strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="item total-liquidity">*/}
            {/*      <h3>Tatal liquidity</h3>*/}
            {/*      <div className="holder-info">*/}
            {/*        <div className="ico">*/}
            {/*          <TotalLiquidityIco/>*/}
            {/*        </div>*/}
            {/*        <div className="info">*/}
            {/*          <span className="number">4053.02k</span>*/}
            {/*          <div className="current-info">*/}
            {/*            <span className="name">USDT</span>*/}
            {/*            <strong>1834.38874</strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="item total-user">*/}
            {/*      <h3>Total user</h3>*/}
            {/*      <div className="holder-info">*/}
            {/*        <div className="ico">*/}
            {/*          <TotalUserIco/>*/}
            {/*        </div>*/}
            {/*        <div className="info">*/}
            {/*          <span className="number">4053.02k</span>*/}
            {/*          <div className="current-info">*/}
            {/*            <span className="name">USDT</span>*/}
            {/*            <strong>1834.38874</strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </>
    )
}

export default Dashboard;