import React from "react";

const Moon = () => {
        return (
          <svg className='moon' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6095 14.6824C10.7461 14.6824 6.80347 10.6669 6.80347 5.71369C6.80347 4.25758 7.14419 2.88252 7.74884 1.66669C4.21866 2.83507 1.66683 6.21303 1.66683 10.198C1.66683 15.1513 5.60942 19.1667 10.4728 19.1667C13.9066 19.1667 16.8813 17.1651 18.3335 14.245C17.4757 14.5289 16.5602 14.6824 15.6095 14.6824Z" stroke="url(#gradient)" stroke-width="1.5"/>
            <defs>
              <linearGradient id="gradient" x1="1.66683" y1="1.66669" x2="22.0396" y2="9.38815" gradientUnits="userSpaceOnUse">
                <stop offset="0.0520833" stop-color="#FF8820"/>
                <stop offset="1" stop-color="#FF386A"/>
              </linearGradient>
            </defs>
          </svg>

        )
}

export {Moon};