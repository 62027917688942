import React from "react";

const ReinvestIco = () => {
        return (
          <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7071 0.292893C10.3166 -0.0976311 9.68342 -0.0976311 9.29289 0.292893L6.29289 3.29289C5.90237 3.68342 5.90237 4.31658 6.29289 4.70711L9.29289 7.70711C9.68342 8.09763 10.3166 8.09763 10.7071 7.70711C11.0976 7.31658 11.0976 6.68342 10.7071 6.29289L9.435 5.0196C9.62153 5.00661 9.80994 5 10 5C12.2094 5 14.208 5.89434 15.6569 7.34315C17.1057 8.79196 18 10.7906 18 13C18 15.2094 17.1057 17.208 15.6569 18.6569C14.208 20.1057 12.2094 21 10 21C7.79058 21 5.79196 20.1057 4.34315 18.6569C2.89434 17.208 2 15.2094 2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13C0 15.7611 1.12038 18.2625 2.92893 20.0711C4.73748 21.8796 7.23885 23 10 23C12.7611 23 15.2625 21.8796 17.0711 20.0711C18.8796 18.2625 20 15.7611 20 13C20 10.2389 18.8796 7.73748 17.0711 5.92893C15.2625 4.12038 12.7611 3 10 3C9.80762 3 9.59699 2.99739 9.40715 3.00814L10.7071 1.70711C11.0976 1.31658 11.0976 0.683418 10.7071 0.292893Z" fill="white"/>
          </svg>
        )
}

export {ReinvestIco};