import React from "react";

const Connect = () => {
        return (
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1998 1.3C11.1998 0.858172 10.8416 0.5 10.3998 0.5C9.95798 0.5 9.5998 0.858172 9.5998 1.3V10.1C9.5998 10.5418 9.95798 10.9 10.3998 10.9C10.8416 10.9 11.1998 10.5418 11.1998 10.1V1.3ZM7.06681 3.62709C7.46837 3.44281 7.64451 2.96789 7.46023 2.56633C7.27595 2.16477 6.80103 1.98863 6.39947 2.17291C3.09643 3.6887 0.799805 7.02585 0.799805 10.9011C0.799805 16.2025 5.09796 20.5 10.3998 20.5C15.7016 20.5 19.9998 16.2025 19.9998 10.9011C19.9998 7.02585 17.7032 3.6887 14.4001 2.17291C13.9986 1.98863 13.5237 2.16477 13.3394 2.56633C13.1551 2.96789 13.3312 3.44281 13.7328 3.62709C16.4886 4.89178 18.3998 7.67414 18.3998 10.9011C18.3998 15.3187 14.8182 18.9 10.3998 18.9C5.98143 18.9 2.3998 15.3187 2.3998 10.9011C2.3998 7.67414 4.31096 4.89178 7.06681 3.62709Z" fill="white"/>
          </svg>
        )
}

export {Connect};