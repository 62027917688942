import React from "react";
import { VkIco } from "../svg/vk";
import { FbIco } from "../svg/fb";
import { TwIco } from "../svg/tw";
import { BcIco } from "../svg/bc";
import { LogoFooter } from "../svg/logoFooter";

const Footer = () => {
    return (
            <footer>
              <div className="container">
                <div className="left">
                  <a href="/" className="logo">
                    <LogoFooter/>
                  </a>
                  <a href="../white_paper_eng.pdf" target={'_blank'} className="police">White Paper (ENG)</a>
                </div>
                <ul className="social">
                  <li>
                    <a href="https://linktr.ee/XXPlatform" target={'_blank'}>
                      <VkIco/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/XXPlatform/" target={'_blank'}>
                      <FbIco/>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/XXPlatform" target={'_blank'}>
                      <TwIco/>
                    </a>
                  </li>
                  {/*<li>*/}
                  {/*  <a href="/" target={'_blank'}>*/}
                  {/*    <BcIco/>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                </ul>
                <div className="copyright">
                  <p>Platform 2021 &copy; All rights reserved</p>
                </div>
              </div>

            </footer>
    )
}

export default Footer;