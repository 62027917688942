import React, {useState} from 'react';
import { Logo } from "../svg/logo";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { ArrowDrop } from "../svg/arrowDrop";
import { Moon } from "../svg/moon";
import { Sun } from "../svg/sun";
import { Connect } from "../svg/connect";
import Navigation from "./components/Navigation";
import { ProfileIco } from "../svg/profile";
import { InfoIco } from "../svg/info";
import { LogoutIco } from "../svg/logout";
import { MenuIco } from "../svg/menu";
import { CloseIco } from "../svg/close";
import ConnectWallet from "../modal/ConnectWallet";
import {useWeb3React} from "@web3-react/core";
import {connectors} from "../../web3/connectojs";

function Header() {
  const {active, library, account, activate, deactivate} = useWeb3React();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const [valueDrop,setValueDrop]=useState('eng');

  const [theme, setTheme] = useState(false);
  const [icoTheme,setIcoTheme]=useState(<Moon/>);
  const ThemeChange = () => {
    setTheme(!theme);
    if(!theme) {
      setIcoTheme(<Sun/>);
      document.body.classList.add('dark');
    } else {
      setIcoTheme(<Moon/>);
      document.body.classList.remove('dark');
    }
  }

  const [showProfile, setShowProfile] = useState(false);
  const profileNav = () => {
    setShowProfile(!showProfile)
    if(!showProfile) {
      document.body.classList.add('hide');
    } else {
      document.body.classList.remove('hide');
    }
  }

  const [showNav, setShowNav] = useState(false);
  const Menu = () => {
    setShowNav(!showNav)
    if(!showNav) {
      document.body.classList.add('hide');
    } else {
      document.body.classList.remove('hide');
    }
  }
  return (
      <header>
        <div className="container">
          <a href="/" className="logo">
            <Logo/>
          </a>
          {(active ?
          <div className={"nav" + (showNav ? ' active': '')}>
            <button className="btn btn-close" onClick={Menu}>
              <CloseIco/>
            </button>
            <Navigation/>
          </div>
          :
          <></>
          )}
          <div className="right">
            <div className="box-btn">
              {/*{(active ?*/}
              {/*    <button className="btn btn-primary btn-menu" onClick={Menu}>*/}
              {/*      <MenuIco/>*/}
              {/*      <span>Menu</span>*/}
              {/*    </button>*/}
              {/*    :*/}
              {/*    <></>*/}
              {/*)}*/}
              {/*<Dropdown isOpen={dropdownOpen} toggle={toggle} className="drop-lang">*/}
              {/*  <DropdownToggle className="btn-lang">*/}
              {/*    <img src={"./img/flag-"+valueDrop+".jpg"} alt="" />*/}
              {/*    <span>{valueDrop}</span>*/}
              {/*    <ArrowDrop/>*/}
              {/*  </DropdownToggle>*/}
              {/*  <DropdownMenu>*/}
              {/*    <DropdownItem onClick={() => setValueDrop("eng")}>*/}
              {/*      <img src={"./img/flag-eng.jpg"} alt="" />*/}
              {/*      <span>Eng</span>*/}
              {/*    </DropdownItem>*/}
              {/*    <DropdownItem onClick={() => setValueDrop("ru")}>*/}
              {/*      <img src="./img/flag-ru.jpg" alt="" />*/}
              {/*      <span>Ru</span>*/}
              {/*    </DropdownItem>*/}
              {/*  </DropdownMenu>*/}
              {/*</Dropdown>*/}
              <button className="btn btn-theme" onClick={ThemeChange}>{icoTheme}</button>
            </div>
            <div className="informer">
              {(active ?
                  <>
                    {/*<button className="btn btn-profile profile-bar" onClick={profileNav}>*/}
                    {/*  <ProfileIco/>*/}
                    {/*</button>*/}
                    <button className="btn btn-profile profile-bar" onClick={() => deactivate()}>
                      <LogoutIco/>
                    </button>
                    <div className={"user-info" + (showProfile ? ' active': '')}>
                      <button className="btn btn-close" onClick={()=>deactivate()}>
                        <CloseIco/>
                      </button>
                      <div className="user">
                        <a href="/" className="number-profile">{account}</a>
                        {/*<button className="btn btn-profile">*/}
                        {/*  /!*<ProfileIco/>*!/*/}
                        {/*</button>*/}
                      </div>
                      <div className="btn-user">
                        {/*<button className="btn btn-ico btn-info">*/}
                        {/*  <InfoIco/>*/}
                        {/*  <span>Info</span>*/}
                        {/*</button>*/}
                        <hr/>
                        <button className="btn btn-ico btn-logout" onClick={() => deactivate()}>
                          <LogoutIco/>
                          <span>Exit</span>
                        </button>
                      </div>
                    </div>
                  </>
                  :
                  <div>
                    <ConnectWallet/>
                    {/*<button className="btn btn-primary btn-connect" onClick={() => setUserActive(true)}><Connect/>connect Wallet</button>*/}
                  </div>
              )}
            </div>
          </div>
        </div>
      </header>
  );
}

export default Header;
